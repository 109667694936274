*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Inter";
  font-size: 1rem;
  color: #2d2d2d;
}

button:hover, button:active{
  outline: none !important;
  border: none !important;
}

.App{
  background-color: white ;
     background: url('./Screens/dots.png') repeat;

}


button:hover{
  cursor: pointer;
}
