@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,1²4..32,100..900&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');



@font-face {
  font-family: 'Ros1', "Inria", serif; 
  src: url("./Fonts/Ros.woff2") format('woff2'); 
    font-display: swap;

}



.Navbar{
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    backdrop-filter: blur(2.6px) saturate(200%);
    -webkit-backdrop-filter: blur(2.6px) saturate(200%);
    background-color: rgba(255, 255, 255, 0.29);
    position: fixed;
    z-index: 99999999;
    top: 0 !important;
    max-width: 1000px !important;
    transition: 0.4s all ease-in-out;
}

.logo{
    height: 50px;
    width: 167px;
    margin-left: 1rem;
    display: flex;
    align-items:  center;
    transition: 0.4s all ease-in-out;
    visibility: visible;
    opacity: 1;
}
.hihi{
  display: flex;
  align-items: baseline;
  padding-bottom: 0;
  margin-bottom: 0;
}
.hideLogo{
  opacity: 0;
  visibility: hidden;
}

.logo img{
    height: 20px;
    width: 20px;
    margin-right: 1px;
}

.animated-text {
    color: #2d2d2d;
    opacity: 0;
    height: 18px !important;
    font-size: clamp(18px, 2.5vw, 21px) !important;
    display: flex;
    font-weight: 500;
    font-family: "Inria", serif;
    animation: fadeIn 0.56s forwards;
}

@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(0px);
    }
    to {
      opacity: 1;
      transform: translateY(-0px);
    }
}

.menuButton{
    height: 40px;
    width: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    outline: none;
}


.menu-icon {
  display: block;
  float: left;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.menu-icon span {
  display: block;
  width: 16px;
  height: 1.7px;
  background: #2d2d2d;
  border-radius: 1px;
  margin-bottom: 2px;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.menu-icon.active {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.menu-icon.active .bar1 {
  -webkit-transform: rotate(0deg) translateY(2.3px);
  -moz-transform: rotate(0deg) translateY(2.3px);
  -ms-transform: rotate(0deg) translateY(2.3px);
  -o-transform: rotate(0deg) translateY(2.3px);
  transform: rotate(0deg) translateY(2.3px);
}
.menu-icon .bar2 {
  opacity: 0;
}

.menu-icon.active .bar3 {
  -webkit-transform: rotate(-90deg) translateX(5px);
  -moz-transform: rotate(-90deg) translateX(5px);
  -ms-transform: rotate(-90deg) translateX(5px);
  -o-transform: rotate(-90deg) translateX(5px);
  transform: rotate(-90deg) translateX(5px);
}


.activatedNavbar{
  z-index: 99999999;
  height: 80px;
  backdrop-filter: blur(9px) saturate(200%);
  -webkit-backdrop-filter: blur(9px) saturate(200%);
  background-color: #f5f5f5c5;}



.popUp{
  width: 100%;
  height: 0px;
  visibility: hidden;
  opacity: 0.7;
  transition: 0.3s all ease-in-out;
  padding-top: clamp(80px, 10vw, 100px);
  overflow: hidden;
  position: fixed;
  z-index: 99999;
  backdrop-filter: blur(9px) saturate(200%);
  -webkit-backdrop-filter: blur(9px) saturate(200%);
  background-color: #f5f5f5c5;
  max-width: 1000px !important;

}

.showpopUp{
  height: 100vh;
  visibility: visible;
  opacity: 1;
  overflow: hidden;
  max-width: 1000px !important;
}

.element{
  width: 100%;
  height: clamp(48px, 8vw, 66px) !important;
  font-family: "Inter";
  font-weight: 600;
  cursor: pointer;
  font-size: clamp(1.7rem, 8vw, 2.4rem) !important;
  margin-bottom: 0.1rem;
  transition: 0.1s all ease-in-out;
  color: #2d2d2d;
  padding: 0;
  padding-left: clamp(0rem, 10vw, 8rem);
}
.element:hover{
  color: #0072ff;
}


.element292{
  display: flex;
  flex-direction: column;
  height: auto !important;
}
.element292 button{
  width: 200px;
  text-align: left;
  height: clamp(44px, 8vw, 60px) !important;
  display: flex;
  font-size: clamp(1.3rem, 8vw, 2.5rem) !important;
  font-weight: 300;
  border: none;
  color: #2d2d2d;
  outline: none;
  background-color: transparent;
  align-items: center;
}


.element292 button:hover{
  border: none;
  outline: none;
}

.dsnhfxuihsfdsnhfxuihsf{
  height: 22px;
  width: 22px;
  margin-right: 0.5rem;
}


.popUpOfSingleProject{
  width: 100%;
  height: 100vh;
  visibility: hidden;
  max-width: 1000px !important;
  opacity: 0.7;
  transition: 0.4s all ease-in-out;
  position: fixed;
  opacity: 0 !important;
  z-index: 999;
  background-color: white;
  background: url('../Screens/dots.png') repeat;

}

.showpopUpOfSingleProject{
  overflow-y: auto !important;
  height: 100vh;
  visibility: visible;
  max-width: 1000px !important;
  padding-bottom: 5rem;
  opacity: 1 !important;
}

.sufowhdusdw{
  margin-top: 0.333rem;
  cursor: pointer;
}

.gallery-container {
  margin: 20px;
  margin-top: 0;
}

.spanishGuitar{
  font-family: "Ros1","Inria", serif;
  letter-spacing: 2px;
  font-size: clamp(1.82rem, 5vw, 2.666rem) !important;
  font-weight: 800; /* Match the bold style */
  background-image: linear-gradient(147deg, #dc17ff, #ff6a00);
  -webkit-background-clip: text; /* Ensures the gradient is clipped to the text */
  -webkit-text-fill-color: transparent; /* Makes the inside of the text transparent to show the gradient */
  display: inline-block;
  margin-bottom: 2rem;
}




.image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: clamp(50px, 10vw, 100px) !important;
  justify-content: center;
}

.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  border: 1px solid rgb(233, 233, 233);
  border-radius: 0rem !important;
  overflow: hidden !important;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;

}

.dynamic-image {
  display: block;
  max-width: 100%;
  height: auto;
  object-fit: contain;
}



.image-wrapper2{
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 78%;
  border: 1px solid rgb(233, 233, 233);
  border-radius: 1.6rem !important;
  overflow: hidden !important;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.gallery-container::-webkit-scrollbar {
  width: 0; /* Set the scrollbar width to 0 */
  visibility: hidden; /* Hide the scrollbar visually */
}

.gallery-container {
  scrollbar-width: none; /* For Firefox */
}

.techonlogiesUsed{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 2px;
  margin-bottom: 1.666rem;
}

.ufsuogfsufsuogfs{
  color: #2d2d2d ;
  font-size: clamp(14px, 2.5vw, 18px) !important;
  font-weight: 400;
  margin-bottom: 0.7rem;
}



.ufsuogfsufsuogfs2 span:first-child{
  background-image: linear-gradient(147deg, #dc17ff,#ff0000, #ff6a00, #ffa600);
  -webkit-background-clip: text; /* Ensures the gradient is clipped to the text */
  -webkit-text-fill-color: transparent; /* Makes the inside of the text transparent to show the gradient */
  display: inline-block;  
  font-size: clamp(14px, 2.5vw, 18px) !important;
  font-weight: 400;
}

.ufsuogfsufsuogfs span:last-child{
  font-size: clamp(14px, 2.5vw, 18px) !important;
  font-weight: 400;
  color: #2d2d2d ;
}

.ufsuogfsufsuogfs2{
  margin-bottom: 0rem;
}

.ehsrigsfighshfig{
  height: 300px !important;
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: clamp(14px, 2.5vw, 18px) !important;
  font-weight: 400!important;
  color: rgb(152, 151, 151)!important;
}


.ufsuogfsufsuogfs3{
  margin-bottom: 1rem;
  margin-top: 1rem;
  color: rgb(179, 179, 179);
}


.toggle-container {
  width: 45px;
  height: 24px;
  background-color: #f0f0f0;
  border: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 1rem; /* Makes it square-ish */
  cursor: pointer;
  position: relative;
}

/* The switch itself */
.toggle-switch {
  width: 21px;
  height: 21px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  transition: all 0.3s ease;
  position: absolute;
}

/* Active state (when toggled) */
.toggle-switch.active {
  left: 21px;
}

/* Default state */
.toggle-switch {
  left: 2px;
}

/* Text inside the toggle */
.toggle-label {
  font-size: 11px;
  font-weight: 500;
  font-family: "Inter";
  color: #333;
}


.dfshgushfgusfuguhsfu{
  display: flex;
  align-items: center;
}

.ufsuogfsufsuogfs2999{
  margin-top: 0.5rem;
}

.jofsjsfgnosfojsofgojsf{
  color: rgb(179, 179, 179) !important;

}

.fa-bars{
  font-size: 1.2rem;
}

.fa-xmark{
  font-size: 1.4rem;
}



@media (min-width: 900px) {
  .spanishGuitar{
    margin-bottom: 2.6rem;
    margin-top: 1rem;
  }
  .ufsuogfsufsuogfs3{
    margin-bottom: 2rem;
  }
}

.slfghousfghusfghhusfg{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 0.3rem;
}
.dsnhfxuihsfdsnhfxuihsf888{
  height: 33px;
  width: 33px;
  margin-right: 0.77rem;
}

.dsnhfxuihsfdsnhfxuihsf909{
  margin-right: 0.77rem;
  height: 22px;
  width: 22px;
}

.Progress{
  width: 70px;
  position: fixed;
  top: 50%;
  left: 20%;
  transform: translate(-50%, -50%); 
  display: flex;
  flex-direction: column;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: 0.4s all ease-in-out;
}




.Progress {
  position: fixed;
  top: 50%;
  left: 100px; /* Default value for screens less than 1300px */
  transform: translateY(-50%);
}




@media (min-width: 1300px) and (max-width: 1400px) {
  .Progress {
    left: 5%;
  }
}
@media (min-width: 1400px) and (max-width: 1500px) {
  .Progress {
    left: 6%;
  }
}
@media (min-width: 1500px) and (max-width: 1500px) {
  .Progress {
    left: 7%;
  }
}
@media (min-width: 1600px) and (max-width: 1700px) {
  .Progress {
    left:9%;
  }
}
@media (min-width: 1700px) and (max-width: 1800px) {
  .Progress {
    left: 10%;
  }
}
@media (min-width: 1800px) and (max-width: 1900px) {
  .Progress {
    left: 11%;
  }
}
@media (min-width: 1900px) and (max-width: 2000px) {
  .Progress {
    left: 13%;
  }
}
@media (min-width: 2000px) and (max-width: 2100px) {
  .Progress {
    left: 14%;
  }
}
@media (min-width: 2100px) and (max-width: 2200px) {
  .Progress {
    left: 16%;
  }
}
@media (min-width: 2200px) and (max-width: 2300px) {
  .Progress {
    left: 17%;
  }
}

@media (min-width: 2300px) and (max-width: 2400px) {
  .Progress {
    left: 18%;
  }
}

@media (min-width: 2400px) and (max-width: 2500px) {
  .Progress {
    left: 19%;
  }
}

@media (min-width: 2500px) and (max-width: 2600px) {
  .Progress {
    left: 20%;
  }
}

@media (min-width: 2600px) and (max-width: 2700px) {
  .Progress {
    left: 21%;
  }
}

@media (min-width: 2700px) and (max-width: 2800px) {
  .Progress {
    left: 22%;
  }
}

@media (min-width: 2800px) and (max-width: 2900px) {
  .Progress {
    left: 23%;
  }
}
@media (min-width: 2900px) and (max-width: 3000px) {
  .Progress {
    left: 25%;
  }
}




.Progress button{
  height: 42px;
  width: 42px;
  border-radius: 50%;
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px 0px;  

  border: none;
  background-color: transparent !important;
  margin-bottom: 1.5rem;
  overflow: hidden;
  transition: 0.15s all ease-in-out;
}
 

.Progress button img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.Progress .osjfjosjofjosojsf{
  background-color: rgb(236, 236, 236) !important;
}

.ShowProgress{
  visibility: visible;
  opacity: 1;
}

.Progress button:hover{
  transform: scale(1.15);
}

.image-wrapper2{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 39% !important;
  height: auto !important;
  border: none !important;
  border-radius: 0px !important;
  overflow: hidden !important;
  margin: 0 auto;
  box-shadow: none !important;
}


.dynamic-image2 {
  max-width: 100% !important;
  height: auto !important;
  object-fit: contain !important;
}




.popUpOfSingleProject::-webkit-scrollbar {
  width: 12px; /* Adjust width as needed */
}

.popUpOfSingleProject::-webkit-scrollbar-thumb {
  background-color: lightgray; /* Default (idle) color */
  border-radius: 5px;
  border: 3px solid transparent; /* Adds space around the thumb */
  background-clip: padding-box; /* Ensures border and thumb colors don't overlap */
}

.popUpOfSingleProject::-webkit-scrollbar-thumb:active {
  background-color: gray; /* Active (scrolled) color */
}

.popUpOfSingleProject::-webkit-scrollbar-track {
  background-color: #f4f4f4; /* Track color */
  border-radius: 5px; /* Optional for smoother look */
}



.activatedNavbar2{
   width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    backdrop-filter: blur(2.6px) saturate(200%);
    -webkit-backdrop-filter: blur(2.6px) saturate(200%);
    position: fixed;
    top: 0 !important;
    max-width: 1000px !important;
    transition: 0.4s all ease-in-out;
    z-index: 99999999;
    height: 80px;
    backdrop-filter:none !important;
    -webkit-backdrop-filter: none !important;
    background-color: white !important;
}
