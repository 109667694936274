



@font-face {
  font-family: 'Ros1'; 
  src: url("../Components/Fonts/Ros.woff2") format('woff2'); 
  font-display: swap;
}

.Home{
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden !important;
  max-width: 1000px !important;
  margin: 0 auto !important;
   background: url('./dots.png') repeat;
}
.SingleProjectPage{
  width: 100%;
  min-height: 100vh;
  background-color: white;
  overflow-x: hidden !important;
  max-width: 1000px !important;
  margin: 0 auto !important;
}
.gradient-text {
  font-size: 1.8rem; /* Adjust size as needed */
  font-weight: 700; /* Match the bold style */
  background: linear-gradient(90deg, #00c6ff, #0072ff, #00b894, #26c485);
  -webkit-background-clip: text; /* Ensures the gradient is clipped to the text */
  -webkit-text-fill-color: transparent; /* Makes the inside of the text transparent to show the gradient */
  display: inline-block; /* Ensures proper layout */
  text-align: center;
  width: 100%;  
  margin-bottom: 0.4rem;
}



.gradient-shadow-container {
  position: relative;
  text-align: center;
  padding: 20px;
  background-color: #fff; /* White background for the text */
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
}

.gradient-shadow-container::after {
  content: "";
  position: absolute;
  bottom: -40px; /* Position the shadow gradient below the content */
  left: 0;
  right: 0;
  height: 80px; /* Height of the shadow */
  background: linear-gradient(90deg, #00c6ff, #0072ff, #00b894, #26c485);
  filter: blur(10px); /* Blurs the gradient for a smooth shadow effect */
  z-index: -1; /* Places the gradient shadow behind the container */
}

.container1{
height: auto;
width: 100%;
display: flex;
flex-direction: column;
padding: 0 clamp(1.7rem, 2.5vw, 4rem);
  padding-bottom: 10rem;
padding-top: calc(9rem + 60px);
}

.container1 p{
width: 100%;
text-align: center;
font-weight: 500;
margin-bottom: 1.666rem;
color: #2d2d2d;
}


.container1 button {
font-size: 15px;
width: 136px;
padding: 0.75rem 0;
border-radius: 3.5rem;
background: #0072ff;
border: none;
color: white;  
font-weight: 400;
text-align: center;
margin: 0 auto;
outline: none;
cursor: pointer; 
transition:0.3s all ease-in-out;  
}

.container1 button:hover{
  background: #0054ba;
}



.marginIt1{
margin-bottom: 1rem !important;
}


.blurVision{
height: 100vh;
width: 100%;
position: fixed;
z-index : 9999999999999999999;
visibility: visible;
opacity: 1;
backdrop-filter: blur(13px) saturate(200%);
-webkit-backdrop-filter: blur(13px) saturate(200%);
background-color: rgba(17, 25, 40, 0);
transition: 1s all ease-in-out;
}
.hideblurVision{
visibility: hidden;
opacity: 0;
}


.container2{
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
padding: 0 clamp(1.7rem, 2.5vw, 4rem);  margin-bottom: clamp(1.666rem, 4vw, 2.8rem) !important;
}

.titleX{
width: 100%;
}


.firstTile{
font-family: 'Inter', serif;
  font-weight: 600; /* Match the bold style */
  background: linear-gradient(90deg, #00c6ff, #0072ff, #00b894, #26c485);
  -webkit-background-clip: text; /* Ensures the gradient is clipped to the text */
  -webkit-text-fill-color: transparent; /* Makes the inside of the text transparent to show the gradient */
  display: inline-block; /* Ensures proper layout */
}

.secondTile , .thirdTitle{
font-size: 1.82rem;
font-weight: 600;
color: #2d2d2d;
}

.container3{
margin-top: 1.25rem;
height: auto;
width: 100%;
padding: 0 clamp(1.7rem, 2.5vw, 4rem);
  padding-right: 1.666rem;
font-weight: 400;
}
.container3 p{
font-size: 15px;
font-weight: 400;
}

.container3 span{
font-weight: 500;
color: #0072ff;
font-size: 15px;
}
.container3 a{
font-weight: 500;
color: #0072ff;
font-size: 15px;

}


.container3 .highlightIt{
font-weight: 400;
color: #2d2d2d;
font-size: 15px;

}
.container3 p{
margin-bottom: 0.666rem;
}


.container3 .shrwfdou{
margin: 0;
}



.skills-scrollbar {
display: flex;
overflow-x: auto;
margin-top: 1.5rem;
padding: 0 2rem;
gap: 20px;
scrollbar-width: none; /* For Firefox */
}

.skills-scrollbar::-webkit-scrollbar {
height: 8px; /* Height of the scrollbar */
}

.skills-scrollbar::-webkit-scrollbar-track {
background: transparent; /* Track background */
}

.skills-scrollbar::-webkit-scrollbar-thumb {
background: #ccc; /* Scrollbar color */
border-radius: 4px; /* Rounded corners */
}

.skill-item {
flex: 0 0 auto; /* Prevent shrinking or stretching */
min-width: 70px;
width: auto;
max-width: 170px;
height: 90px;
background: transparent !important; /* Optional background for the case */
border-radius: 10px; /* Rounded corners */
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
}

.skill-item img {
margin-bottom: 7px;
width: 50px;
height: 50px;
object-fit: contain; /* Ensures images fit nicely within the case */
border-radius: 5px; /* Optional rounded corners for images */
}



.skills-scrollbar::-webkit-scrollbar {
display: none; /* For Chrome, Safari, and Edge */
}

.skill-item div{
font-weight: 500;
color: #2d2d2d;
font-size: 15px; 
width: 100%;
text-align: center;
}

.SingleProject{
height: auto;
width: 100%;
cursor: pointer;
display: flex;
flex-direction: column;
padding: 1.5rem ;
border-radius:1rem ;
margin-bottom: 7rem;
  transition: 0.15s all ease-in-out;
}

.titleProject{
color: #2d2d2d;
flex-direction: column;
display: flex;
} 


.imageProject {
width: 100%;          
height: auto;         
display: block;      
object-fit: cover;   
border-radius: 8px;   
margin-top: 1rem;
margin-bottom: 1rem;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.0666);
}

.typeOfApp{
width: auto;
padding: 0.1rem 0.4rem;
border-radius: 2rem;
font-weight: 500;
font-size: 10px;
color: white;
background-image: linear-gradient(147deg, #dc17ff, #ff6a00);
margin-left: 0.666rem;
}

.ishfwd{
display: flex;
align-items: center;
margin-top: 1rem;
justify-content: space-between;
}

.projectNameIII{
font-size: 1.5rem;
font-weight: 700;
font-family: 'Ros1','Inria', serif;
letter-spacing: 2px;
background: linear-gradient(35deg, #dc17ff, #ff6a00,#ff6a00, #ff6a00, #ff6a00, #ff2f00);
-webkit-background-clip: text; /* Ensures the gradient is clipped to the text */
-webkit-text-fill-color: transparent; /* Makes the inside of the text transparent to show the gradient */
display: inline-block;
}

.ishfwd button{
height: 35px;
width: 35px;
outline: none;
border: none;
border-radius: 0.3rem;
display: flex;
align-items: center;
justify-content: center;
}


.enfsd{
font-weight: 500;
font-size: 11px;
color: #2d2d2d;
margin-bottom: 0.3rem;
}


.sifwdisf{
width: calc(100% - 40px);
display: flex;
flex-direction: column;
}
.sifwdisf span:first-child{
font-size: 15px;
font-weight: 500;
margin-bottom: 0.3rem;
}
.sifwdisf span:last-child{
font-size: 14px;
font-weight: 400;
color: gray;
}

.sofjwduhsoufwdg{
font-size: 13px !important;
font-weight: 400 !important;
color: rgb(160, 160, 160) !important;
}


.fa-arrows-left-right{
color: #0072ff;
font-size: 13px;
}


.dsfiwd{
background-color: transparent !important;
display: flex !important;
align-items: center !important;
justify-content: right !important;
}





.timeline {
position: relative;
margin: 0 auto;
padding: 0;
width: 74%;
margin-top: 2rem;
}

.timeline-item {
padding-bottom: 1.444rem;
position: relative;
}

.timeline-item::before {
content: "";
position: absolute;
left: -3px;
top: 5px;
width: 9px !important;
height: 9px !important;
background-color: #3498db;
border-radius: 50%;
z-index: 1;
}

.timeline-item::after {
content: "";
position: absolute;
left: -0px;
top: 10px;
height: 100%;
width: 2px;
background: #ccc;
}

.timeline-item:last-child::after {
display: none;
}

.timeline-content {
padding-left: 40px;
}

.timeline-date {
font-size: 13px;
color: gray;
font-weight: 400;
}

.spacer3{
height: 3rem;
}

.idgnfvosofnv1{
font-weight: 500;
font-size: 15px;
color: #2d2d2d;
}

.idgnfvosofnv2, .idgnfvosofnv3{
color: gray;
font-weight: 400;
font-size: 15px;
}
.container3333 p{
width: 100%;
display: flex;
font-size: 15px;

align-items: center;
}

.container3333 p:first-child{
margin-bottom: 1rem;
font-size:15px;

}
.container3333 .isfwsufhgisfwng{
font-size: 15px;
}
.container3333 .isfwsufhgisfwng a{
font-size: 15px;
margin-left: 0.5rem;
}

.dosfnwusfodfosfd{
width: 100%;
height: 45px;
border: 1px solid gainsboro;
background-color: rgb(240, 240, 240);
border-radius: 0.3rem;
outline: none;
color: #2d2d2d;
font-size: 15px;
font-weight: 400;
transition: 0s all ease-in-out;
cursor: pointer;
margin-top: 1.4rem;
display: flex;
align-items: center;
justify-content: center;
}

.dfhgihsfghisfhg{
text-decoration: none;
font-size: 16px;
font-weight: 400;
}

.container398{
padding-bottom: 1rem;
}
.container398 p{
text-align: center;
width: 100%;
font-size: 13px;
font-weight: 400;
margin-bottom: 0.15rem !important;
color: rgb(160, 160, 160);
}
.sifhshfgihsif{
height: 20px !important;
font-weight: 400 !important;
}





.smallPadding{
padding-left: 0;
padding-right: 0;
}



.sfhgshgsiugiushuifg{
height: 100vh;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
padding: 0 3rem;  
}

.sfhgshgsiugiushuifg h4{
font-size: 2rem; /* Adjust size as needed */
font-weight: 700; /* Match the bold style */
background: linear-gradient(90deg, #00c6ff, #0072ff, #00b894, #26c485);
-webkit-background-clip: text; /* Ensures the gradient is clipped to the text */
-webkit-text-fill-color: transparent; /* Makes the inside of the text transparent to show the gradient */
display: inline-block;
margin-bottom: 2rem;

}

.sfhgshgsiugiushuifg span{
font-size: 15px;
max-width: 500px;
color: gray;
font-weight: 400;
text-align: center;
}

.isfwsufhgisfwng span{
margin-left: 0.5rem;
}




.gradient-text{
  font-size: clamp(2.2rem, 5vw, 3.2rem) !important;
}

.titleX span{
  font-size: clamp(1.8rem, 5vw, 3rem) !important;
}




.container3 p{
  font-size: clamp(15px, 2.5vw, 19px) !important;
  font-weight: 400;
  }
  
  .container3 span{
  font-weight: 500;
  color: #0072ff;
  font-size: clamp(15px, 2.5vw, 19px) !important;
  }
.container3 .highlightIt{
font-weight: 400;
color: #2d2d2d;
font-size: clamp(15px, 2.5vw, 19px) !important;

}




.projectNameIII{
  font-size: clamp(1.5rem, 5vw, 2.5rem) !important;
}

.sifwdisf span:first-child{
  font-size: clamp(15px, 2.5vw, 19px) !important;
  font-weight: 500;
  }
  .sifwdisf span:last-child{
    font-size: clamp(14px, 2.5vw, 18px) !important;
  font-weight: 400;
  }

  .idgnfvosofnv1{
    font-weight: 500;
    font-size: clamp(15px, 2.5vw, 19px) !important;
    }

    .idgnfvosofnv2, .idgnfvosofnv3{
      color: gray;
      font-weight: 400;
      font-size: clamp(15px, 2.5vw, 17px) !important;
    }
    .timeline-date {
      font-size: clamp(13px, 2.5vw, 17px) !important;
      color: gray;
      font-weight: 400;
      }

.spacer{
  height:9rem !important;
  }
  
.spacer2{
  height: 3rem !important;
}
.spacer67{
  height: 5rem !important;
}
.sifhshfgihsif{
  font-size: 17px !important;
}
  


.SingleProject09090{
  margin-bottom: 0 !important;
}  


.NAVBARxoFpROJECTS{
  display: flex;
  align-items: center;
  justify-content: left; 
  width: 100%;
  max-width: 1000%;
  height: 70px;
  padding-left: 1.3rem;
  cursor: pointer;
  position: sticky;
  top: 0;
  z-index: 999999;
  background-color: #f5f5f5;
}


.NAVBARxoFpROJECTS span{
  color: rgb(166, 166, 166);
  font-size: 0.9rem;
}

.NAVBARxoFpROJECTS i{
  color: rgb(166, 166, 166);
  font-size: 1rem;
}


@media (min-width: 900px) {
  .titleX span{
    font-weight: 700;
  }
  .spacer{
    height:14rem !important;
    }
    
  .spacer2{
    height: 8rem !important;
  }
  .spacer67{
    height: 8rem !important;
  }
  .SingleProject{
    margin-bottom: 10rem !important;
  }
  .SingleProject09090{
    margin-bottom: 0 !important;
  }  
  .sifhshfgihsif{
    font-size: 19px !important;
  }
  .enfsd{
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 0.3rem;
  }
  .projectNameIII{
    margin-bottom: 1rem;    
  }  
  .fa-angles-right{
    font-size: 1.7rem;
  }

  .fa-caret{
    font-size: 1.7rem;
  }

.imageProject {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

}




.container3333 p{
  font-size: clamp(16px, 2.5vw, 19px) !important;
}
  
.container3333 p:first-child{
  font-size: clamp(16px, 2.5vw, 19px) !important;
}
.container3333 .isfwsufhgisfwng{
  font-size: clamp(16px, 2.5vw, 19px) !important;
}
.container3333 .isfwsufhgisfwng a{
  font-size: clamp(16px, 2.5vw, 19px) !important;
}
.container398 p{
  font-size: clamp(13px, 2.5vw, 16px) !important;
  font-weight: 400;
  color: rgb(160, 160, 160);
}
.firstTile {
  font-size: clamp(1.6rem, 5vw, 2.5rem) !important;
} 
.secondTile , .thirdTitle {
  font-size: clamp(1.6rem, 5vw, 2.5rem) !important;
} 



.No8KLlL{
  font-family: "Inter" !important;
  letter-spacing: 0px !important;
}


.xxx{
  color : "red";
  /* 
    FCUK THE FUCKING FUCK
  */
}
